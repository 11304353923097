import ReactHtmlParser from 'react-html-parser';

const RRSSLink = ({ href, content }) => {
  return (
    <a href={href}
       className="d-inline-block">{ ReactHtmlParser(content)}</a>
  )
}

export default RRSSLink;
