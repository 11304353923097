import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

let header = {
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'x-api-key': apiKey
};

const validateToken = (token) => {

  let url = `${baseUrl}/tokens/${token}`;

  return axios.get(url, {
            headers: header
         });
};

const storeBankData = (data) => {

  let url = `${baseUrl}/bank-data`;

  return axios.post(url, data, {
            headers: header
         });
};


export default {
    validateToken, storeBankData
};
