import { Fragment, useState, useEffect, useRef } from 'react';
import Banner from './Banner.js';

import SweetAlert from 'sweetalert2-react';
import ReCAPTCHA from 'react-google-invisible-recaptcha';

import kentico from '../services/KenticoService.js';
import api from '../services/ApiService.js';

const Form = ({ token }) => {
  const [selectedCountry, setSelectedCountry] = useState(
    process.env.REACT_APP_DEFAULT_COUNTRY
  );
  const [expired, setExpired] = useState(false);
  const [done, setDone] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(true);
  const [message, setMessage] = useState({
    title: '',
    text: '',
    show: false,
  });
  const [elements, setElements] = useState({});
  const [modular, setModular] = useState({});
  const [options, setOptions] = useState({
    banks: [],
    countries: [
      { name: 'Chile', code: 'cl' },
      { name: 'Argentina', code: 'ar' },
    ],
    account_types: [
      { name: 'Cuenta de Ahorro', code: 'Cuenta de Ahorro' },
      { name: 'Cuenta Vista', code: 'Cuenta Vista' },
      { name: 'Cuenta Corriente', code: 'Cuenta Corriente' },
    ],
  });
  const [fields, setFields] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const recaptchaRef = useRef();

  const getModularInfo = (code) => {
    return new Promise(function (resolve, reject) {
      kentico
        .getItemDataPreview(`${code}`)
        .then((response) => {
          resolve(response.data.modular_content);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const validateToken = () => {
    return new Promise(function (resolve, reject) {
      api
        .validateToken(token)
        .then(async (response) => {
          // console.log(response);
          resolve(response);
        })
        .catch((err) => {
          // console.log(err.response);
          resolve(err.response);
        });
    });
  };

  const getData = async () => {
    return kentico
      .getItemDataPreview('refund_bank_fields')
      .then(async (response) => {
        setElements(response.data.item.elements);
        setModular(response.data.modular_content);
        setData({ country: selectedCountry });

        let resp = await validateToken();

        if (typeof resp.status !== 'undefined' && resp.status === 200) {
          getModularInfo(`bf_${selectedCountry}`).then((result) =>
            formatFields(result, setFields)
          );
          getModularInfo(`blo_${selectedCountry}`).then((result) =>
            formatBankList(result, setOptions)
          );
        } else {
          setExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    handleSpecificEvents(e.target.name, e.target.value);
  };

  const handleSpecificEvents = (field, value) => {
    switch (field) {
      case 'country':
        if (value !== '') {
          setSelectedCountry(value);
          setData({
            [field]: value,
          });
          resetErrors();
          setFields([]);
          getModularInfo(`bf_${value}`).then((result) =>
            formatFields(result, setFields)
          );
          getModularInfo(`blo_${value}`).then((result) =>
            formatBankList(result, setOptions)
          );
        } else {
          setMessage({
            title: 'Mensaje',
            text: 'Debe seleccionar un País para continuar',
            show: true,
          });
        }
        break;
      case 'bank':
        setData({
          ...data,
          [field]: options.banks.find((bank) => bank.code === value).name,
        });
        break;
      default:
        setData({
          ...data,
          [field]: value,
        });
    }
  };

  const clearAlert = () => {
    setMessage({
      title: '',
      text: '',
      show: false,
    });
  };

  const formatBankList = (list, setterFunc) => {
    let array = [];
    for (const key in list) {
      let obj = {
        name: list[key].elements.name.value,
        code: list[key].elements.code.value,
      };

      array.push(obj);
    }

    setterFunc({
      ...options,
      banks: array,
    });
  };

  const compare = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };

  const formatFields = (list, setterFunc) => {
    let array = [];
    for (const key in list) {
      let obj = {
        name: list[key].elements.name.value,
        type: list[key].elements.type.value,
        placeholder: list[key].elements.placeholder.value,
        options: list[key].elements.options.value,
        function: list[key].elements.function.value,
        order: list[key].elements.order.value,
      };
      array.push(obj);
    }
    setterFunc(array.sort(compare));
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      return;
    }

    if (!accepted) {
      setMessage({
        title: 'Mensaje',
        text: 'Debe confirmar su solicitud para continuar',
        show: true,
      });
      return;
    }

    if (errorCaptcha) {
      setMessage({
        title: 'Mensaje',
        text: 'Error en validación de ReCAPTCHA',
        show: true,
      });
      return;
    }

    data.rut = data.rut.replace(/[.]/g, '');

    let body = {
      token: token,
      data: data,
      culture: process.env.REACT_APP_DEFAULT_CULTURE,
    };

    api
      .storeBankData(body)
      .then(async (response) => {
        if (response.status === 201 || response.status === 200) {
          setDone(true);
        } else {
          console.log(response);
          setMessage({
            title: 'Mensaje',
            text: 'Ha ocurrido un error al intentar enviar sus datos, por favor intente nuevamente',
            show: true,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setMessage({
          title: 'Mensaje',
          text: 'Ha ocurrido un error al intentar enviar sus datos, por favor intente nuevamente',
          show: true,
        });
      });
  };

  const resetErrors = () => {
    let errors = {};

    fields.forEach((field, index) => {
      errors[field.name] = false;
    });

    setError(errors);
  };

  const validateFields = () => {
    let resp = true;
    let errors = {};

    fields.forEach((field, index) => {
      if (typeof data[field.name] === 'undefined' || data[field.name] === '') {
        resp = false;
        errors[field.name] = true;
      } else {
        errors[field.name] = false;
      }
    });

    setError(errors);

    return resp;
  };

  //ReCAPTCHA event
  const noErrorCaptchaDetected = () => {
    setErrorCaptcha(false);
  };

  //ReCAPTCHA event
  const errorCaptchaDetected = () => {
    setErrorCaptcha(true);
  };

  useEffect(getData, []);

  return (
    <Fragment>
      <Banner elements={elements} />
      <SweetAlert
        show={message.show}
        title={message.title}
        text={message.text}
        onConfirm={() => clearAlert()}
      />
      <div className="container">
        <div className="row">
          <div className="dev_text">
            <h4 className="font-lato-bold my-5">
              {elements.title_check_bank_info
                ? elements.title_check_bank_info.value
                : ''}
            </h4>
            <div className="alert">
              <img src="https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/13ecdcef-d22d-4508-abcc-d581df0c355e/alert-white.png" />
              <h5 className="font-lato-regular ml-3">
                {elements.alert_before_save
                  ? elements.alert_before_save.value
                  : ''}
              </h5>
            </div>
          </div>
          <div className="col-12 pt-5">
            <div className="row">
              {!expired && !done ? (
                <Fragment>
                  <div className="col-12 mb-5 pt-3 account dev-results">
                    <h4 className="mt-1 d-inline color-blue-js font-lato-black">
                      <img
                        className="icon-form"
                        src="devoluciones/img/card.png"
                        alt=""
                      />
                      {elements.type_bank_data
                        ? '  ' + elements.type_bank_data.value
                        : ''}
                    </h4>
                    <form id="form1">
                      <div className="bank-account">
                        <div className="row box-bank-account">
                          {fields.map((field, index) => (
                            <Fragment key={index}>
                              <div className="col-md-6">
                                {field.type === 'select' ? (
                                  <Fragment>
                                    <select
                                      className="input-card form-control"
                                      id={field.name}
                                      name={field.name}
                                      defaultValue={
                                        field.name === 'country'
                                          ? selectedCountry
                                          : ''
                                      }
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <option value="">
                                        {field.placeholder}
                                      </option>
                                      {options[field.options].map(
                                        (bank, index) => (
                                          <option value={bank.code} key={index}>
                                            {bank.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <input
                                      className="input-card form-control"
                                      name={field.name}
                                      type={field.type}
                                      id={field.name}
                                      onChange={(e) => handleChange(e)}
                                      placeholder={field.placeholder}
                                    />
                                  </Fragment>
                                )}
                                {typeof error[field.name] !== 'undefined' &&
                                error[field.name] ? (
                                  <span className="error-message-span">
                                    Campo Requerido
                                  </span>
                                ) : null}
                              </div>
                            </Fragment>
                          ))}
                        </div>
                        <div className="custom-control custom-checkbox mb-5">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="declaration"
                            name="declaration"
                            checked={accepted}
                            readOnly
                          />
                          <label
                            className="custom-control-label color-red-js font-weight-bold"
                            onClick={() => setAccepted(!accepted)}
                          >
                            {elements.accept_terms_text
                              ? elements.accept_terms_text.value
                              : ''}
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-12 text-right mb-1">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_KEY_RECAPTCHA}
                      onResolved={() => noErrorCaptchaDetected()}
                      onLoaded={() => noErrorCaptchaDetected()}
                      onError={() => errorCaptchaDetected()}
                      onExpired={() => errorCaptchaDetected()}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      className="primary-btn float-right"
                      onClick={() => handleSubmit()}
                    >
                      {elements.save_btn ? elements.save_btn.value : ''}
                    </button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="col-12 mb-5 pt-3 account dev-results">
                    <h4 className="mt-1 d-inline color-blue-js font-lato-black">
                      {expired
                        ? 'Su sesión ha expirado'
                        : 'Información enviada satisfactoriamente'}
                    </h4>
                    <div className="bank-account">
                      {expired
                        ? 'Por favor, solicite un nuevo enlace de acceso para actualización de datos'
                        : 'Sus datos han sido enviados satisfactoriamente.'}
                    </div>
                    <br />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Form;
