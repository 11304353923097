import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Form from '../components/Form.js';

const App = (props) => {
  return (
    <div className="App">
      <Header/>
      <Form
        token={typeof props.match.params.token !== "undefined" ? props.match.params.token : ''}
      />
      <Footer/>
    </div>
  );
}

export default App;
