import axios from 'axios';

const baseUrl = process.env.REACT_APP_DELIVERY_URL;
const projectId = process.env.REACT_APP_DELIVERY_ID;

const previewUrl = process.env.REACT_APP_DELIVERY_PREVIEW_URL;
const previewToken = process.env.REACT_APP_DELIVERY_PREVIEW_TOKEN;


let header = {
    'Content-Type': 'application/json',
    'Accept': '*/*'
};

const getItemData = (obj) => {

  let url = `${baseUrl}/${projectId}/items/${obj}`;

  return axios.get(url, {
            headers: header
         });
};

const getItemDataPreview = (obj) => {

  let url = `${previewUrl}/${projectId}/items/${obj}`;

  header = {
    ...header,
    Authorization: `Bearer ${previewToken}`
  };

  return axios.get(url, {
            headers: header
         });
};

export default {
    getItemData, getItemDataPreview
};
