const Banner = ({elements}) => {
  return (
    <div className="container-fluid px-0">
        <div className="row no-gutters">
            <div className="col-12">
              {
                elements.banner_desktop &&
                elements.banner_desktop.value &&
                elements.banner_desktop.value.length>0 ?
                  <img src={elements.banner_desktop.value[0].url} className="img-fluid cms-banner d-md-block d-none" alt={elements.banner_desktop.name}/>
                :
                  null
              }
              {
                elements.banner_mobile &&
                elements.banner_mobile.value &&
                elements.banner_mobile.value.length>0 ?
                  <img src={elements.banner_mobile.value[0].url} className="img-fluid cms-banner d-md-none d-block" alt={elements.banner_mobile.name}/>
                :
                  null
              }
            </div>
        </div>
    </div>
  )
}

export default Banner;
