import { useState, useEffect } from 'react';
import kentico from '../services/KenticoService.js';

const Header = () => {

  const [elements, setElements] = useState({});

  const getData = () => {

    return kentico.getItemDataPreview("header").then((response) => {
      setElements(response.data.item.elements);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(getData, []);

  return (
    <header className="main-header fixed-top scroll-friendly">
        <div className="container">
            <nav className="navbar navbar-expand-lg align-items-center">
                <a className="navbar-brand" href="https://jetsmart.com/cl/es/">
                  <img
                    src={ elements.logo_colored ? elements.logo_colored.value[0].url : '' }
                    alt={ elements.logo_colored_alt ? elements.logo_colored_alt : '' }
                    title={ elements.logo_colored_title ? elements.logo_colored_title : '' }
                    className="logo-jetsmart img-fluid"
                    />
                </a>
                <div className="d-xl-none d-lg-none d-flex">
                    <ul className="navbar-nav d-flex mobile-navbar-countries nav-justified justify-content-center align-items-center">
                        <li className="nav-item nav-countries dropdown d-flex h-100 align-middle justify-content-center align-items-center">
                            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                               aria-haspopup="true" aria-expanded="false"><span className="flag rounded-pin border-white-2"><img
                                    src={ process.env.REACT_APP_URL + "/jetsmart_static/img/flags/131-chile.svg"}
                                    className="img-fluid rounded-pin"/></span><span>Chile</span></a>
                            <div className="dropdown-menu">
                                <h5 className="mx-4 pb-1 mt-1 text-light font-weight-bold border-bottom border-white">{ elements.title_select_country ? elements.title_select_country.value : '' }</h5>
                                <a className="dropdown-item" href="javascript:void(0);"><span
                                        className="flag rounded-pin border-white-2"><img
                                        src={ process.env.REACT_APP_URL + "/jetsmart_static/img/flags/131-chile.svg"} className="img-fluid rounded-pin"/></span>Chile</a>
                            </div>
                        </li>
                    </ul>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><span className="sr-only">Toggle navigation</span><span
                            className="icon-bar"></span><span className="icon-bar"></span><span
                            className="icon-bar"></span><small>MENU</small></span>
                    </button>
                </div>
                <div className="col-xl-5 col-lg-4 d-xl-inline-block d-lg-inline-block d-none">&nbsp;</div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav w-100 nav-justified justify-content-center align-items-center">
                        <li className="nav-item dropdown d-flex h-100 align-middle justify-content-center align-items-center">
                            <a className="nav-link" href="https://booking.jetsmart.com/V2/Register"><span
                                    className="hover-border-bottom-center">{ elements.text_link_register ? elements.text_link_register.value : '' }</span></a>
                        </li>
                        <li className="nav-item d-flex h-100 align-middle justify-content-center align-items-center">
                            <a className="nav-link"
                               href="https://booking.jetsmart.com/V2/Login?url=https://jetsmart.com/cl/es/"><span
                                    className="hover-border-bottom-center">{ elements.text_login ? elements.text_login.value : '' }</span></a>
                        </li>
                        <li className="nav-item nav-countries dropdown d-xl-flex d-lg-flex d-none h-100 align-middle justify-content-center align-items-center">
                            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                               aria-haspopup="true" aria-expanded="false"><span className="flag rounded-pin border-white-2"><img
                                    src={ process.env.REACT_APP_URL + "/jetsmart_static/img/flags/131-chile.svg"}
                                    className="img-fluid rounded-pin"/></span><span>Chile</span></a>
                            <div className="dropdown-menu">
                                <h5 className="mx-4 pb-1 mt-1 text-light font-weight-bold border-bottom border-white">{ elements.title_select_country ? elements.title_select_country.value : '' }</h5>
                                <a className="dropdown-item" href="javascript:void(0);"><span
                                        className="flag rounded-pin border-white-2"><img
                                        src={ process.env.REACT_APP_URL + "/jetsmart_static/img/flags/131-chile.svg"} className="img-fluid rounded-pin"/></span>Chile</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
  )
}

export default Header;
