import {Fragment} from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Form from '../components/Form.js';

const NotFound = (props) => {

  return (
    <div className="App">
      <Header/>
      <div className="container">
          <div className="row">
              <div className="col-12 pt-5">
                  <div className="row">
                    <Fragment>
                      <div className="col-12 mb-5 pt-3 account dev-results">
                        <h4 className="mt-1 d-inline color-blue-js font-lato-black">
                          PÁGINA NO ENCONTRADA
                        </h4>
                        <div className="bank-account">
                          LA PÁGINA QUE INTENTA CONSULTAR NO HA SIDO ENCONTRADA
                        </div>
                        <br/>
                      </div>
                    </Fragment>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default NotFound;
