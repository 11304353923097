import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import history from './history';

import App from './pages/App';
import NotFound from './pages/404';


ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
     <div>
       <Switch>
        <Route exact path="/:country/:lang/bank-information/:token" component={App} />
        <Route exact path="/" component={NotFound} />
       </Switch>
     </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
